<script>
import Layout from "../../layouts/main"
import PageHeader from "@/components/page-header"
import List from './list'
import Form from './form'

export default {
  components: {
    Layout,
    PageHeader,
    List,
    Form,
  },
  data() {
    return {
      title: "Cashbox",
      items: [
        {
          text: "VMCore"
        },
        {
          text: "Cashbox",
          active: true
        }
      ]
    }
  },
  methods: {
    onActiveChange(b) {
      this.$refs.list.setActive(!b)
    },
    onRefresh() {
      this.$refs.list.fetchData()
    },
    onEdit(id) {
      this.$refs.form.initUpdate(id)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <Form ref="form" @refresh="onRefresh" @active_change="onActiveChange" />
        <List ref="list" @onEdit="onEdit" />
      </div>
    </div>
  </Layout>
</template>